/**
 * @generated SignedSource<<b0bd53a07d7e5dc40f1ef2ea6277d654>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProfileAccountPageQuery$variables = {};
export type ProfileAccountPageQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountProfilePage_viewer">;
  } | null;
};
export type ProfileAccountPageQuery = {
  response: ProfileAccountPageQuery$data;
  variables: ProfileAccountPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileAccountPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountProfilePage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileAccountPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pictureUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69964e209eb48540203162890961fbf6",
    "id": null,
    "metadata": {},
    "name": "ProfileAccountPageQuery",
    "operationKind": "query",
    "text": "query ProfileAccountPageQuery {\n  viewer {\n    ...AccountProfilePage_viewer\n    id\n  }\n}\n\nfragment AccountProfilePage_viewer on Viewer {\n  ...UserNameForm_viewer\n  ...UserAvatarForm_viewer\n  ...UserEmailForm_viewer\n  ...UserPasswordForm_viewer\n  ...UserProfilePictureForm_viewer\n}\n\nfragment Avatar_userProfile on UserProfile {\n  displayName\n  pictureUrl\n}\n\nfragment UserAvatarForm_viewer on Viewer {\n  user {\n    profile {\n      ...Avatar_userProfile\n      id\n    }\n    id\n  }\n}\n\nfragment UserEmailForm_viewer on Viewer {\n  user {\n    id\n    email\n  }\n}\n\nfragment UserNameForm_viewer on Viewer {\n  user {\n    id\n    profile {\n      name\n      id\n    }\n  }\n}\n\nfragment UserPasswordForm_viewer on Viewer {\n  user {\n    id\n  }\n}\n\nfragment UserProfilePictureForm_viewer on Viewer {\n  user {\n    id\n    profile {\n      pictureUrl\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c08be7f1c624fdb3dd4c4bcde4fecf5";

export default node;

import Layout from '@4c/layout';
import { useRouter } from 'found';
import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { graphql } from 'react-relay';
import { useMutation } from 'react-relay-mutation';

import InfoPageCard from 'components/InfoPageCard';
import { REDIRECT_ON_FINISH } from 'config/delays';
import * as routes from 'utils/routes';

import BlankPage from './BlankPage';
import type { VerifyEmailChangePageMutation as Mutation } from './__generated__/VerifyEmailChangePageMutation.graphql';
import BusyToDoneAnimation from './animation/BusyToDoneAnimation';

const mutation = graphql`
  mutation VerifyEmailChangePageMutation(
    $input: AcceptEmailChangeVerificationInput!
  ) {
    acceptEmailChangeVerificationOrError(input: $input) {
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const messages = defineMessages({
  title: {
    id: 'verifyEmailChangePage.title',
    defaultMessage: 'E-mail Address Change Request',
  },
  subtitle: {
    id: 'verifyEmailChangePage.subtitle',
    defaultMessage: '',
  },
});

export default function VerifyEmailChangePage() {
  const { match, router } = useRouter();
  const { token } = match.params;

  const [mutate, { loading, error }] = useMutation<Mutation>(mutation, {
    variables: {
      input: {
        token,
      },
    },
    onCompleted: () => {
      setTimeout(() => router.replace(routes.root()), REDIRECT_ON_FINISH);
    },
  });

  useEffect(() => {
    mutate();
  }, [mutate]);

  return (
    <BlankPage>
      <InfoPageCard title={messages.title}>
        <BusyToDoneAnimation
          busy={loading}
          theme={error ? 'failure' : 'success'}
        />
        {!loading && (
          <Layout justify="center">
            {error ? (
              <FormattedMessage
                id="verifyEmailChangePage.error"
                defaultMessage="An error has occured"
              />
            ) : (
              <FormattedMessage
                id="verifyEmailChangePage.success"
                defaultMessage="Your email address has been changed successfully"
              />
            )}
          </Layout>
        )}
      </InfoPageCard>
    </BlankPage>
  );
}

/**
 * Code in part from https://github.com/github/fetch/blob/master/LICENSE
 */

const support = {
  blob:
    'FileReader' in window &&
    'Blob' in window &&
    (() => {
      try {
        // eslint-disable-next-line no-new
        new Blob();
        return true;
      } catch (e) {
        return false;
      }
    })(),
};

function parseHeaders(rawHeaders: string) {
  const headers = new Headers();
  rawHeaders.split(/\r?\n/).forEach((line) => {
    const parts = line.split(':');
    const key = parts.shift()!.trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
}

export interface Init extends RequestInit {
  onProgress: (ev: ProgressEvent) => any;
}

export const fetchOptions = {
  disable: false,
};

export default function fetchWithProgress(
  url: string,
  { body, headers, method = 'GET', onProgress, ...options }: Init,
): Promise<Response> {
  if (fetchOptions.disable) {
    const response = new FormData();
    response.append('ok', 'true');
    response.append('type', 'simple');
    const event = new ProgressEvent('upload', {
      lengthComputable: true,
      loaded: 1,
      total: 1,
    });
    onProgress(event);
    return Promise.resolve(new Response(response));
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.upload.onprogress = onProgress;

    xhr.onload = () => {
      const responseHeaders = parseHeaders(xhr.getAllResponseHeaders() || '');

      const respOptions: ResponseInit = {
        status: xhr.status,
        statusText: xhr.statusText,
        headers: responseHeaders,
      };

      const respBody = xhr.response || xhr.responseText;
      resolve(new Response(xhr.status === 204 ? null : respBody, respOptions));
    };

    xhr.onerror = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.ontimeout = () => {
      reject(new TypeError('Network request failed'));
    };

    xhr.open(method, url, true);

    Object.entries(headers || {}).forEach(([name, value]) => {
      xhr.setRequestHeader(name, value);
    });

    if (options.credentials === 'include') {
      xhr.withCredentials = true;
    }

    if ('responseType' in xhr && support.blob) {
      xhr.responseType = 'blob';
    }

    if (body instanceof ReadableStream) {
      reject(new TypeError('Body should not be a stream'));
      return;
    }

    xhr.send(body || null);
  });
}

import Layout, { LayoutProps } from '@4c/layout';
import classNames from 'classnames';
import React from 'react';

import Text, { Props as TextProps } from 'components/Text';

import './Header.scss';
import Button from './Button';

interface Props extends React.ComponentPropsWithoutRef<typeof Layout> {
  children?: React.ReactNode;
}

interface ActionProps extends TextProps {
  children: React.ReactNode;
}

const rootClass = 'Header';

const Actions = React.forwardRef<any, Props>(({ children, ...props }, ref) => (
  <div {...props} ref={ref} className={classNames(`${rootClass}__actions`)}>
    {children}
  </div>
));

const Action = React.forwardRef<any, ActionProps>(
  ({ children, ...props }, ref) => (
    <Button
      {...props}
      type="button"
      ref={ref}
      variant="navbar"
      className={classNames(`${rootClass}__button`)}
    >
      <Text size="sm" color="primary">
        {children}
      </Text>
    </Button>
  ),
);

const PageHeader = (props: LayoutProps & { children: React.ReactNode }) => (
  <div
    className={classNames(rootClass, `${rootClass}--page-header`)}
    {...props}
  />
);

const Header = React.forwardRef<any, Props>(({ children, ...props }, ref) => (
  <div {...props} ref={ref} className={classNames(rootClass)}>
    {children}
  </div>
));

export default Object.assign(Header, {
  PageHeader,
  Action,
  Actions,
});

import Form from '@bfly/ui/Form';
import isEqual from 'lodash/isEqual';
import expr from 'property-expr';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormProps as BaseFormProps } from 'react-formal';
import { FormHandle } from 'react-formal/esm/Form';
import toast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import {
  MutationNode as BaseMutationNode,
  useMutation,
} from 'react-relay-mutation';
import {
  MutationConfig as BaseMutationConfig,
  MutationParameters,
} from 'relay-runtime';
import { useUncontrolled } from 'uncontrollable';
import type { AnyObjectSchema, InferType } from 'yup';

import { relayResponseHasError } from 'utils/relayUtils';

import type { RelayForm_error$data as RelayFormError } from './__generated__/RelayForm_error.graphql';

export declare type MutationConfig<T extends MutationParameters> = Partial<
  Omit<BaseMutationConfig<T>, 'mutation' | 'onCompleted'>
> & {
  onCompleted?(response: T['response']): void;
};

declare type RelayExtraVariables<T extends RelayOperationWithError> = Omit<
  T['variables'],
  'input'
>;

export declare type RelayMutationInput<T extends RelayOperationWithError> = {
  input: T['variables']['input'];
  extraVariables?: keyof RelayExtraVariables<T> extends never
    ? undefined
    : RelayExtraVariables<T>;
};

export declare type RelayMutationConfig<T extends RelayOperationWithError> =
  Omit<MutationConfig<T>, 'variables'> & Partial<RelayMutationInput<T>>;

export interface RelayMutationErrorFragment {
  readonly __typename: string;
  readonly message: string | null;
}

export declare type ValuesOf<T> = T extends {
  [s: string]: infer V;
}
  ? V
  : unknown;

export declare class RelayMutationError<T extends RelayOperationWithError> {
  name: string;

  errorType: string;

  message: string;

  data: Omit<NonNullable<ValuesOf<T['response']>>, '__typename' | 'message'>;

  constructor(payload: NonNullable<ValuesOf<T['response']>>);
}

export interface RelayOperationWithError<T = unknown> {
  variables: {
    input: unknown;
  };
  response: {
    [index: string]: (T & Partial<RelayMutationErrorFragment>) | null;
  };
}

export type MutationNode<T extends RelayOperationWithError> =
  BaseMutationNode<T>;

export function isMutationError<T>(
  err: Error,
): err is RelayMutationError<RelayOperationWithError<T>> {
  return err.name === 'RelayMutationError';
}

export interface FormProps<
  TSchema extends AnyObjectSchema,
  TValue = Record<string, any>,
> extends BaseFormProps<TSchema, TValue> {
  formContext?: any;
}

export type FormErrors = Record<string, any[]>;
export interface RelayFormProps<
  T extends RelayOperationWithError,
  TSchema extends AnyObjectSchema,
> extends Omit<FormProps<TSchema>, 'onError' | 'runMutation'> {
  formContext?: any;
  mutation: MutationNode<T>;
  onChange?: (input: InferType<TSchema>, changedPaths?: string[]) => void;
  getInput: (
    value: InferType<TSchema>,
  ) => MaybePromise<T['variables']['input']>;
  onCompleted?: RelayMutationConfig<T>['onCompleted'];
  onError?: (error: RelayMutationError<T>) => void;
  updater?: RelayMutationConfig<T>['updater'];
  getUploadables?: (
    value: InferType<TSchema>,
  ) => MaybePromise<RelayMutationConfig<T>['uploadables']>;
  onFormError?: FormProps<TSchema>['onError'];
  /** Show mutation errors in the form instead of with toasts. */
  addServerErrorsToFormErrors?: boolean;
  children?: React.ReactNode;
  confirm?: (input: Obj) => Promise<boolean>;
  ref?: React.RefObject<FormHandle>;
}

const defaultProps = {
  addServerErrorsToFormErrors: false,
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment RelayForm_error on ErrorInterface {
    ...mutationError_error @relay(mask: false)
    ... on InvalidInputError {
      fields {
        message
        path
      }
    }
  }
`;

function RelayForm<
  T extends RelayOperationWithError,
  TSchema extends AnyObjectSchema,
>(props: RelayFormProps<T, TSchema>) {
  const {
    mutation,
    value,
    onChange,
    getInput,
    getUploadables,
    onCompleted,
    updater,
    addServerErrorsToFormErrors,
    // Allow errors to be controlled
    errors: formErrors,
    onFormError,
    onError,
    confirm,
    ref,
    ...formProps
  } = useUncontrolled(props, {
    value: 'onChange',
  }) as typeof props; // TODO: for some reason we need to coerce here because useUncontrolled type doesn't work well

  const [clientErrors, setClientErrors] = useState<FormErrors>({});
  const [serverErrors, setServerErrors] = useState<FormErrors>({});

  const valueRef = useRef(value);
  const prevValue = valueRef.current;
  valueRef.current = value;

  // === should be enough theoretically, but we do a deep equality check in case a form updates on every render
  if (!isEqual(value, prevValue)) {
    const nextServerErrors = { ...serverErrors };

    // Server errors on any changed fields are no longer relevant.
    Object.keys(serverErrors).forEach((path) => {
      if (Form.getter(path, value) !== Form.getter(path, prevValue)) {
        delete nextServerErrors[path];
      }
    });

    // Form-level server errors are irrelevant now, too.
    delete nextServerErrors[''];

    setServerErrors(nextServerErrors);
  }

  const errors = useMemo(
    () => ({
      ...(formErrors || clientErrors),
      ...serverErrors,
    }),
    [clientErrors, formErrors, serverErrors],
  );

  const [mutate] = useMutation<T>(mutation, {
    onCompleted: (response) => {
      const error = relayResponseHasError(response);
      if (error) {
        toast.error(error.message);
      } else if (onCompleted) {
        onCompleted(response);
      }
    },
    updater,
  });

  const handleMutationError = useCallback(
    (error: Error) => {
      const nextServerErrors = {};

      if (isMutationError<RelayFormError>(error)) {
        if (onError) {
          try {
            Object.assign(
              nextServerErrors,
              onError(error as RelayMutationError<T>),
            );
            setServerErrors(nextServerErrors);
            return;
          } catch (e) {
            // if an exception is thrown continue with the default error handling
          }
        }

        if (error.errorType === 'InvalidInputError') {
          for (const { message, path } of error.data.fields!) {
            // Build yup path
            const fieldName = expr.join(path.slice(1));
            (nextServerErrors as any)[fieldName] = [{ message }];
          }
        } else if (addServerErrorsToFormErrors) {
          (nextServerErrors as any)[''] = [{ message: error.message }];
        } else {
          toast.error(error.message);
        }
      } else {
        toast.error(
          <FormattedMessage
            id="relayForm.serverError"
            defaultMessage="There was a problem processing your request"
          />,
        );
      }

      setServerErrors(nextServerErrors);
    },
    [addServerErrorsToFormErrors, onError],
  );

  const submitForm = useCallback(
    async (input: any) => {
      const serialized = formProps.schema!.cast(input, {
        stripUnknown: true,
      }) as InferType<TSchema>;

      if (confirm) {
        if (!(await confirm(serialized))) return;
      }

      try {
        await mutate({
          variables: { input: await getInput(serialized) },
          uploadables: getUploadables && (await getUploadables(serialized)),
        });
      } catch (e) {
        // We can't use onError here, because we want the promise to reject
        // on a mutation error.
        setServerErrors({});
        setClientErrors({});
        handleMutationError(e as Error);
        throw e;
      }

      setServerErrors({});
      setClientErrors({});
    },
    [
      confirm,
      formProps.schema,
      getInput,
      getUploadables,
      handleMutationError,
      mutate,
    ],
  );

  return (
    <Form
      {...formProps}
      ref={ref}
      value={value}
      errors={errors}
      warnOnUnsavedNavigation={process.env.VERSION !== '[TESTING]'}
      onChange={onChange}
      onError={onFormError || setClientErrors}
      submitForm={submitForm}
    />
  );
}

RelayForm.defaultProps = defaultProps;

export default RelayForm;

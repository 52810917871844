import { createRoot } from 'react-dom/client';
// Import yupConfig before other local code for its side effects.
import './yupConfig';

function renderApp() {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const clientApplication = require('./clientApplication').default();
  const container = document.getElementById('app')!;
  const root = createRoot(container);
  root.render(clientApplication);
}

interface HotLoadedNodeModule extends NodeModule {
  hot: any;
}

function runApp() {
  renderApp();
  const moduleObj = module as HotLoadedNodeModule;

  if (moduleObj.hot) {
    moduleObj.hot.accept(/clientApplication/, renderApp);
  }
}

window.addEventListener('load', runApp);
